@blue           : #1f98b6;
@hero-light-blue: #f0fbff;
@hero-blue      : #bde6f8;
@care-blue      : #f0f9fd;

html {
  overflow-x: hidden;
}

body {
  max-width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  font-family: 'Jost';
  font-size: 1.125rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.6;
  color: #000;
}

.text-black {
  color: #000;
}

.text-light-blue {
  color: #23bbe1;
}

.text-underline {
  text-decoration: underline;
}

.bg-dark-green {
  background: linear-gradient(194deg,#44c501 37%,#00830d 76%);
}

.bg-blue {
  background: linear-gradient(180deg, #0a9edd 0%, #034d6c 100%);
}

.bg-dark-blue {
  background: linear-gradient(0deg, #00414c 0%, #045a69 100%);
}

.btn {
  border: none;
  border-radius: 40px;
  font-size: 17px;
  padding: 12px;
  font-weight: 700;
  line-height: 1.5;
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #919191;
}

.btn-blue {
  color: #fff;
  background-color: @blue;
}

.btn-outline-blue {
  color: #fff;
  border: 1px solid transparent;
  border-color: @blue;
}

.btn-blue:focus, .btn-blue:hover {
  color: #fff;
  box-shadow: unset;
}

.btn-outline-blue:focus, .btn-outline-blue:hover {
  color: #fff;
  background-color: @blue;
}

.btn-link:focus, .btn-link:hover {
  text-decoration: none;
  color: #000;
  box-shadow: unset;
}

#quickbar {
  position: fixed;
  z-index: 930;
  top: 50vh;
  right: 25px;
  width: 100px;
  background-color: #00333c;
  margin-top: -175px;
  padding: 15px;
  border-radius: 0.5rem;
}

#dak-mobile {
  height: 63px;
  font-size: 10px;
  padding-top: 9px;
  background: linear-gradient(0deg, rgba(240,111,63,1) 0%, rgba(244,168,104,1) 100%);

  img {
    border-left: 1px solid #fff;
    margin-top: -3px;
    margin-left: 15px;
    padding-left: 15px;
  }
}

nav {
  background-color: #035563;

  .navbar-brand img {
    width: 100px;
  }

  .nav-link, .nav-link:hover {
    color: @blue;
    font-weight: 500;
  }
  
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  }
}

.modal-backdrop.show {
  opacity: 0.8;
}

.hero {
  background-color: #035563;
  background-repeat: no-repeat;
  background-position-x: right;

  h1 {
    font-size: 42px;
    color: #dce3e7;
    font-weight: 900;
    line-height: 1;
  }

  p {
    color: #d9e3e5;
    line-height: 1.4;
    max-width: 250px;
  }

  .row-top div {
    margin-top: 4rem;
  }

  #img-hero-gym {
    margin-top: -170px;
    margin-left: -345px;
  }
  
  #img-hero-quote {
    position: absolute;
    width: 60px;
    top: -45px;
    left: -25px;
  }
  
  #img-hero-physio {
    position: absolute;
    z-index: 910;
    width: 310px;
    top: 280px;
    right: -80px;
  }

  .btn {
    position: relative;
    z-index: 920;
    font-size: 20px;
    font-weight: normal;
  }

  #btn-contact {
    color: #265158;
    font-size: 18px;
    font-weight: 500;
    padding: 15px;
  }

  #btn-video {
    color: #fff;
    background-color: #11748a;
    font-size: 18px;
    padding: 15px;

    img {
      margin-top: -5px;
    }
  }

  #modal-hero-video {
    .modal-content {
      background-color: transparent;
      border: 0;
    }

    #modal-btn-close {
      padding: 1rem !important;

      img {
        transform: rotate(45deg);
      }
    }

    iframe {
      width: 100%;
      height: 60vh;
      border: 0;
    }
  }
}

.usps {
  background-color: #209dbc;

  img {
    margin-bottom: -35px;
  }

  .heading-usp {
    font-size: 25px;
    font-weight: 600;
    line-height: 1.2;
  }

  .carousel-item {
    min-height: 410px;
  }

  .carousel-indicators {
    li {
      width: 15px;
      height: 15px;
      margin: 0px 10px;
      border: 3px solid #b7e8f5;
      border-radius: 50%;
      background-color: transparent;
      background-clip: unset;
    }

    .active {
      background-color: #b7e8f5;
    }
  }
}

#care {
  #care-overview {
    color: #00414d;

    #care-overview-heading {
      font-size: 33px;
    }
  }
}

#contact {
  a {
    font-size: 14px;
  }

  a, #contact-intro {
    font-weight: 600;
  }

  img {
    margin-top: -5px;
  }
}

#faq {
  color: #00414d;

  .btn-link {
    color: #00414d;
    font-weight: 600;
    border-radius: 0px;
  }

  button[aria-expanded="true"] {
    #icon-faq-plus {
      transform: rotate(45deg);
    }
  }
}

#modalAppointment {

	.modal-dialog {
		max-width: 100vw;
		margin: 0;
	}

	.modal-content {
		width: 100vw;
		min-height: 100vh;
		border: 0;
		border-radius: 0;

		.container {
			max-width: 600px;
		}

		.modal-header {
			#imgModalAppt {
				background-color: #035563;
				border-radius: 10px;
				padding: 5px;
			}

			#btnCloseModalAppt {
				font-size: 40px;
			}
		}

		#contAppointmentTime {
			border: 1px solid #ced4da;
			border-radius: 0.25rem;
		}

		#appointmentTime {
			border: none;
			border-right: 15px solid transparent;
		}

		.btn-primary, .btn-primary:focus, .btn-primary:active {
			background-color: #209dbc !important;
		}

	}

}

.footer {
  background: linear-gradient(194deg, #03434d 37%,#00171b 76%);
  font-size: 15px;
}

.footer .h4 {
  font-size: 15px;
  font-weight: 600;
}

.footer hr {
  width: 100%;
  height: 0;
  border: 0;
  border-bottom: 1px solid #054455;
  border-top: 1px solid #054455;
}

a[href="http://www.etracker.de"] {
  display: none;
}

@media (min-width: 400px) {
  .hero h1 {
    font-size: 50px !important;
  }

  #img-hero-physio {
    width: 335px !important;
  }

  #contact {
    a {
      font-size: unset !important;
    }
  }
}

@media (min-width: 450px) {
  .hero h1 {
    font-size: 57px !important;
  }

  #img-hero-physio {
    width: 355px !important;
  }
}

@media (min-width: 500px) {
  .hero h1 {
    font-size: 64px !important;
  }

  .hero p {
    padding-left: 3rem !important;
  }

  #img-hero-quote {
    width: 60px !important;
    top: -35px !important;
    left: 0px !important;
  }

  #img-hero-physio {
    width: 400px !important;
  }
}

@media (min-width: 576px) {
  .hero h1 {
    font-size: 70px !important;
  }

  .hero .btn {
    font-size: 21px !important;
  }

  #img-hero-physio {
    width: 450px !important;
    top: 252px !important;
    right: -120px !important;
  }
}

@media (min-width: 768px) {
  .hero h1 {
    font-size: 77px !important;
  }

  .hero .row-top p {
    max-width: 400px !important;
  }

  #img-hero-quote {
    width: 75px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    position: absolute;
    width: 161px;
    height: 161px;
    z-index: 900;
    top: -20px;
    right: 10px;
    background: linear-gradient(0deg, rgba(237,107,57,1) 0%, rgba(239,164,101,1) 100%);
    border-radius: 50%;

    #cont-hero-dak {
      position: absolute;
      top: 50%;
      margin-top: -55px;

      #img-hero-dak {
        width: 90px;
        margin: auto;
      }

      #divider-hero-dak {
        width: 50%;
        margin: 7px auto;
        border-top: 1px solid #fff;
      }

      #text-hero-dak {
        width: 75%;
        margin: auto;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        font-size: 11px;
      }
    }
  }

  #img-hero-physio {
    width: 416px !important;
    top: 172px !important;
    right: -40px !important;
  }

  #modal-hero-video {
    .modal-dialog {
      width: 75vw;
      max-width: unset;
      margin: 0;

      iframe {
        height: 75vh;
      }
    }
  }

  .usps .carousel-inner {  
    width: 75% !important;
  }
}

@media (max-width: 991px) {
  #care {
    #care-overview-heading {
      font-size: 25px !important;
      line-height: 1.3;
    }
  }

  #contact {
    .h2 {
      font-size: 1.4rem;
    }
  }

  #faq {
    .h2 {
      font-size: 1.7rem;
    }
  }
}

@media (min-width: 992px) {
  nav {
    .container {
      max-width: 1240px;
    }

    .navbar-brand img {
      width: 140px;
    }
  }

  .hero h1 {
    font-size: 85px !important;
    max-width: 600px !important;
  }

  .hero .row-top {
    position: relative;
    z-index: 900;

    p {
      max-width: 440px !important;
    }
  }

  .hero .btn {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }

  #img-hero-quote {
    width: 83px !important;
    top: -40px !important;
    left: -20px !important;
  }

  #badge-hero-dak {
    width: 179px !important;
    height: 179px !important;
    right: 20px !important;

    #cont-hero-dak {
      margin-top: -60px !important;

      #img-hero-dak {
        width: 100px !important;
      }

      #text-hero-dak {
        font-size: 12px !important;
      }
    }
  }

  #img-hero-physio {
    width: 462px !important;
    top: 92px !important;
    right: 35px !important;
  }

  #info img {
    width: 250px;
    border-radius: 0% !important;
  }

  .footer hr {
    margin: 35px 0;
  }
}

@media (min-width: 1200px) {
  .hero h1 {
    font-size: 104px !important;
    max-width: 850px !important;
  }

  .hero .row-top p {
    font-size: 20px !important;
    max-width: 530px !important;
    padding-top: 1rem !important;
  }

  .hero .btn {
    font-size: 25px !important;
  }

  #img-hero-quote {
    width: 98px !important;
    left: -38px !important;
  }

  #badge-hero-dak {
    width: 210px !important;
    height: 210px !important;
    top: -20px !important;

    #cont-hero-dak {
      margin-top: -66px !important;

      #img-hero-dak {
        width: 110px !important;
      }

      #text-hero-dak {
        font-size: 14px !important;
      }
    }
  }

  #img-hero-physio {
    width: 544px !important;
    top: 50px !important;
    right: 30px !important;
  }

  #modal-hero-video #modal-btn-close img {
    width: 40px !important;
  }
}

@media (min-width: 1400px) {
  #quickbar, #cont-img-hero-gym, #cont-img-hero-quote {
    display: block !important;
  }

  .hero h1 {
    font-size: 139px !important;
    max-width: unset !important;
  }

  .hero .row-top p {
    font-size: 26px !important;
    max-width: 650px !important;
    margin-left: 0px !important;
    padding-top: 0px !important;
    padding-left: 0px !important;
  }

  #img-hero-quote {
    width: 130px !important;
    top: -85px !important;
    left: -100px !important;
  }

  #badge-hero-dak {
    width: 280px !important;
    height: 280px !important;
    right: -110px !important;

    #cont-hero-dak {
      margin-top: -98px !important;

      #img-hero-dak {
        width: 180px !important;
      }

      #text-hero-dak {
        font-size: 18px !important;
      }
    }
  }

  #img-hero-physio {
    width: 725px !important;
    top: 160px !important;
    right: -240px !important;
  }
}