.bob-frame.contains-w1-bob-wizard-form1,
.bob-frame.contains-w1-bob-wizard-form2,
.bob-frame.contains-w1-bob-wizard-form3,
.bob-frame.contains-w1-bob-wizard-form4,
.bob-frame.contains-w1-bob-wizard-form5,
.bob-frame.contains-w1-bob-wizard-pro {
    .bob-window {
        max-width: 600px;
        margin: 0 auto;

        .bob-header {
            img {
                margin: 10px 0 0 32px;
            }
        }
    }
}


.bob-frame.contains-w1-bob-wizard-form5 {
    .container-form {
        padding: 10px 10px 10px 15px;

        legend {
            margin-bottom: 0;
        }
    }

    .checkbox {
        label {
            font-size: 16px;
            font-weight: 300;
            padding-left: 5px;
        }
    }
}

@media screen and (min-width: 576px) {

    .bob-frame.contains-w1-bob-wizard-form5 {
        .container-form {
            padding: 30px;
        }

        .checkbox {
            label {
                font-size: 17px;
            }
        }
    }

    .bob-frame.contains-bob-frame-content-passage {

        .passage .container {
            padding: 0;
            max-width: 100%;
        }

        .bob-window {
            max-width: 750px;
        }
    }
}

.form-group-email {

    label {
        white-space: nowrap;
    }
}
